/* eslint-disable @angular-eslint/no-conflicting-lifecycle */
import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  DoCheck,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  KeyValueDiffer,
  KeyValueDiffers,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import { DaterangepickerFnsComponent } from './daterangepicker-fns.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateRange, LocaleFnsConfig } from './daterangepicker.config';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { CONTENT_CLASS_NAME } from '@core/config/const/html-elements';
import { DateTimeFnsService } from '@src/app/core/services/date-time-fns.service';
import { isEmpty, isEqual } from 'lodash-es';
import { isSameDay } from 'date-fns';

@Directive({
  selector: '[appNgxDaterangepickerFns]',
  /* eslint-disable @angular-eslint/no-host-metadata-property */
  host: {
    '(keyup.esc)': 'hide()',
    '(blur)': 'onBlur()',
    '(click)': 'open()',
    '(keyup.enter)': 'dateUpdatedWithInput()',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DaterangepickerFnsDirective),
      multi: true,
    },
  ],
})
export class DaterangepickerFnsDirective implements OnInit, OnChanges, DoCheck, AfterViewInit {
  public picker: DaterangepickerFnsComponent;
  @Input()
  minDate: Date;
  @Input()
  maxDate: Date;
  @Input()
  autoApply: boolean;
  @Input()
  alwaysShowCalendars: boolean;
  @Input()
  showCustomRangeLabel: boolean;
  @Input()
  linkedCalendars: boolean;
  @Input()
  dateLimit: number = null;
  @Input()
  singleDatePicker: boolean;
  @Input()
  showWeekNumbers: boolean;
  @Input()
  showISOWeekNumbers: boolean;
  // @Input()
  showDropdowns: boolean = true;
  @Input()
  showClearButton: boolean;
  @Input()
  ranges: any;
  @Input()
  opens: string = 'right';
  @Input()
  drops: string = 'down';
  firstMonthDayClass: string;
  @Input()
  lastMonthDayClass: string;
  @Input()
  emptyWeekRowClass: string = 'd-none';
  @Input()
  firstDayOfNextMonthClass: string;
  @Input()
  lastDayOfPreviousMonthClass: string;
  @Input()
  keepCalendarOpeningWithRange: boolean;
  @Input()
  showRangeLabelOnInput: boolean;
  @Input()
  showCancel: boolean = false;
  // timepicker variables
  @Input()
  timePicker: boolean = false;
  @Input()
  timePickerIncrement: number = 1;
  @Input()
  timePickerSeconds: boolean = false;
  @Input()
  chooseDate: boolean = false;
  @Input() classCustom: string = '';
  @Input() changePositionOnScroll: boolean = true;
  @Input() outputOnlySingleDate: boolean = false;
  @Input() fullSizeDateRangePicker: boolean = true;
  @Input() extraInsideElementId: string = null;
  notForChangesProperty: Array<string> = ['locale', 'endKey', 'startKey'];
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() change: EventEmitter<Object> = new EventEmitter();
  @Output() rangeClicked: EventEmitter<Object> = new EventEmitter();
  @Output() datesUpdated: EventEmitter<DateRange> = new EventEmitter();
  private _onChange = Function.prototype;
  private _onTouched = Function.prototype;
  private localeDiffer: KeyValueDiffer<string, any>;

  constructor(
    public viewContainerRef: ViewContainerRef,
    public _changeDetectorRef: ChangeDetectorRef,
    private _el: ElementRef,
    private _renderer: Renderer2,
    private differs: KeyValueDiffers,
    private dateTimeFnsService: DateTimeFnsService
  ) {
    this.drops = 'down';
    this.opens = 'right';
    viewContainerRef.clear();
    const componentRef = viewContainerRef.createComponent(DaterangepickerFnsComponent);
    this.picker = <DaterangepickerFnsComponent>componentRef.instance;
    this.picker.inline = false; // set inline to false for all directive usage
  }

  private _value: any;

  get value() {
    return this._value || null;
  }

  set value(val) {
    this._value = val;
    this._onChange(val);
    this._changeDetectorRef.markForCheck();
  }

  _locale: LocaleFnsConfig = {};

  get locale(): any {
    return this._locale;
  }

  @Input() set locale(value) {
    this._locale = { ...this.dateTimeFnsService.config, ...value };
  }

  @Input()
  private _endKey: string = 'endDate';

  @Input() set endKey(value) {
    if (value !== null) {
      this._endKey = value;
    } else {
      this._endKey = 'endDate';
    }
  }

  private _startKey: string = 'startDate';

  @Input() set startKey(value) {
    if (value !== null) {
      this._startKey = value;
    } else {
      this._startKey = 'startDate';
    }
  }

  get isRelativePosition(): boolean {
    const element = this._el.nativeElement;
    const parentPosition = window.getComputedStyle(element.parentElement).getPropertyValue('position').toLowerCase();
    const elementPosition = window.getComputedStyle(element).getPropertyValue('position').toLowerCase();
    return parentPosition === 'relative' || elementPosition === 'relative';
  }

  ngOnInit() {
    this.picker.rangeClicked.subscribe((range: any) => {
      this.rangeClicked.emit(range);
    });
    this.picker.datesUpdated.subscribe((range: any) => {
      this.datesUpdated.emit(range);
    });
    this.picker.choosedDate.subscribe((change: any) => {
      if (change) {
        const value = {};
        value[this._startKey] = change.startDate;
        value[this._endKey] = change.endDate;
        this.value = value;
        this.change.emit(value);
        if (typeof change.chosenLabel === 'string') {
          this._el.nativeElement.value = change.chosenLabel;
        }
      }
    });
    this.picker.firstMonthDayClass = this.firstMonthDayClass;
    this.picker.lastMonthDayClass = this.lastMonthDayClass;
    this.picker.emptyWeekRowClass = this.emptyWeekRowClass;
    this.picker.firstDayOfNextMonthClass = this.firstDayOfNextMonthClass;
    this.picker.lastDayOfPreviousMonthClass = this.lastDayOfPreviousMonthClass;
    this.picker.drops = this.drops;
    this.picker.opens = this.opens;
    this.localeDiffer = this.differs.find(this.locale).create();
    this.picker.classCustom = this.classCustom;
    // if (this.picker.timePicker) {
    //   this.picker.timePickerLeftActionClicked.subscribe((value: boolean) => {
    //     if (value) {
    //       this.picker.isDropdownLeftVisible.next(false);
    //     }
    //   });

    //   this.picker.timePickerRightActionClicked.subscribe((value: boolean) => {
    //     if (value) {
    //       this.picker.isDropdownRightVisible.next(false);
    //     }
    //   });
    // }
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const change in changes) {
      if (changes.hasOwnProperty(change)) {
        if (this.notForChangesProperty.indexOf(change) === -1) {
          this.picker[change] = changes[change].currentValue;
        }
      }
    }
  }

  ngDoCheck() {
    if (this.localeDiffer) {
      const changes = this.localeDiffer.diff(this.locale);
      if (changes) {
        this.picker.updateLocale(this.locale);
      }
    }
  }

  onBlur() {
    this._onTouched();
    this.dateUpdatedWithInput(false);
  }

  open(event?: any) {
    this.picker.show(event);
    setTimeout(() => {
      this.setPosition();
    }, 100);
  }

  hide(e?) {
    this.picker.hide(e);
  }

  toggle(e?) {
    if (this.picker.isShown) {
      this.hide(e);
    } else {
      this.open(e);
    }
  }

  clear() {
    this.picker.clear();
  }

  writeValue(value) {
    if (value && typeof value === 'string') {
      value = {
        [this._startKey]: this.dateTimeFnsService.formatDate(value),
        [this._endKey]: this.dateTimeFnsService.formatDate(value),
      };
    }
    this.setValue(value);
  }

  registerOnChange(fn) {
    this._onChange = fn;
  }

  registerOnTouched(fn) {
    this._onTouched = fn;
  }

  /**
   * Set position of the calendar
   */
  setPosition() {
    let style;
    let containerTop;
    const container = this.picker.pickerContainer.nativeElement;
    const element = this._el.nativeElement;
    const elementHTML = element as HTMLElement;
    const boundingBox = elementHTML.getBoundingClientRect();
    if (this.isRelativePosition) {
      containerTop =
        this.drops && this.drops === 'up' ? element.offsetTop - container.clientHeight + 'px' : boundingBox['height'] + 35 + 'px';
    } else if (this.drops && this.drops === 'up') {
      containerTop = element.offsetTop - container.clientHeight + 'px';
    } else {
      containerTop = boundingBox['y'] + boundingBox['height'] + 12 + 'px';
    }
    if (this.opens === 'left') {
      style = {
        top: containerTop,
        left: element.offsetLeft - container.clientWidth + element.clientWidth + 'px',
        right: 'auto',
      };
    } else if (this.opens === 'center') {
      style = {
        top: containerTop,
        left: element.offsetLeft + element.clientWidth / 2 - container.clientWidth / 2 + 'px',
        right: 'auto',
      };
    } else {
      style = {
        top: containerTop,
        left: element.offsetLeft + 'px',
        right: 'auto',
      };
    }
    if (style) {
      this._renderer.setStyle(container, 'top', style.top);
      this._renderer.setStyle(container, 'left', style.left);
      this._renderer.setStyle(container, 'right', style.right);
      this._renderer.setStyle(container, 'z-index', 12);
      this._renderer.setStyle(container, 'transition', 'all 0.4s ease 0s, background-color 0s, border-color 0s');
    }
  }

  ngAfterViewInit(): void {
    if (this.changePositionOnScroll) {
      this.handleScrollChange();
    }
  }

  handleScrollChange() {
    const el: HTMLElement = document.querySelector(`.${CONTENT_CLASS_NAME}`);
    const scroll$ = fromEvent(el, 'scroll');
    scroll$.pipe(debounceTime(250), distinctUntilChanged()).subscribe(() => {
      this.setPosition();
    });
  }

  /**
   * For click outside of the calendar's container
   * @param event event object
   * @param targetElement target element object
   */
  @HostListener('document:click', ['$event', '$event.target'])
  outsideClick(event, targetElement: any): void {
    if (!targetElement
      || (this.picker.isDropdownLeftVisible.value && !this.picker.timePickerLeftActionClicked.value)
      || (this.picker.isDropdownRightVisible.value && !this.picker.timePickerRightActionClicked.value)
    ) {
      return;
    }
    if (targetElement.classList.contains('ngx-daterangepicker-action')) {
      return;
    }

    if (this.handleTimePickerAction(this.picker.timePickerLeftActionClicked, this.picker.isDropdownLeftVisible)
      || this.handleTimePickerAction(this.picker.timePickerRightActionClicked, this.picker.isDropdownRightVisible)
    ) {
      return;
    }

    if (this.extraInsideElementId && targetElement.id === this.extraInsideElementId) {
      this.toggle();
      return;
    }

    const clickedInside = this._el.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.hide();
    }
  }

  private handleTimePickerAction(actionClicked, isDropdownVisible) {
    if (actionClicked.value) {
      actionClicked.next(false);
      isDropdownVisible.next(false);
      return true;
    }
    return false;
  }

  private setValue(val: any) {
    if (val) {
      this.value = val;
      if (val[this._startKey]) {
        this.picker.setStartDate(val[this._startKey]);
      }
      if (val[this._endKey]) {
        this.picker.setEndDate(val[this._endKey]);
      }
      this.picker.calculateChosenLabel(this.value.date_range);
      if (this.picker.chosenLabel) {
        this._el.nativeElement.value = this.picker.chosenLabel;
      }
    } else {
      this.picker.clear();
    }
    if (this.chooseDate && !val) {
      this.picker.chosenLabel = this.dateTimeFnsService.config.chooseLabel;
      this._el.nativeElement.value = this.picker.chosenLabel;
    }
  }

  dateUpdatedWithInput(onInput = true) {
    let value = `${this._el.nativeElement.value}`;
    const format = this.dateTimeFnsService.convertFnsDateFormat(this.locale.format);

    const isInputInvalid = this.validateInputValue();
    if (isInputInvalid && !onInput && (isEmpty(value) || !value) ) {
      // blur event and not select date yet.
      return;
    }
    if (isInputInvalid) {
      // update the input value with the previous value
      value = this.picker.chosenLabel;
    }

    if (this.singleDatePicker && this.dateTimeFnsService.isValidDateString(value, format)) {
      // Case 1: Single date picker
      const updatedValue = {
        [this._startKey]: this.dateTimeFnsService.createDateFromFormat(value, format),
        [this._endKey]: this.dateTimeFnsService.createDateFromFormat(value, format),
      };
      // Time update
      if (this.timePicker) {
        const timeFormat = 'HH:mm:ss';
        const now = this.dateTimeFnsService.currentDate;
        if (isSameDay(updatedValue[this._startKey], now)) {
          const value = this.picker.compareSameDateTime(updatedValue[this._startKey], now);
          updatedValue[this._startKey] = value;
          updatedValue[this._endKey] = value;
        }
        const timeValue = this.dateTimeFnsService.formatDate(updatedValue[this._startKey], timeFormat);
        const time = timeValue.split(':');
        const left = this.picker.timepickerVariables.left;
        if (!left.selected) {
          left.selected = updatedValue[this._startKey];
          left.selectedHour = time[0];
          left.selectedMinute = time[1];
          left.selectedSecond = time[2];
        }

        this.picker.datesUpdated.emit(updatedValue[this._startKey]);
      }

      // Set the value of the date range picker
      this.setValue(updatedValue);
      if (onInput) {
        // Re-render the calendar to show the updated value
        this.picker.reRenderCalendar();
        // Find the target date in the picker's calendar
        const target = this.findDateTargetInPicker(updatedValue[this._startKey], this.picker.calendarVariables.left.calendar as Date[][]);
        // Get the DOM element for the left calendar
        const tbody = this.picker.leftCalendarElRef.nativeElement as HTMLElement;
        // Get the DOM element for the target date
        const trEl = tbody.querySelectorAll('tr')[target.row].querySelectorAll('td')[target.col] as HTMLElement;
        // Click the target date to update UI on selected date
        !trEl.className.includes('disabled') ? trEl.click() : null;

        this.timePicker ? this.picker.clickApply() : null;
      }
    } else if (!this.singleDatePicker && !this.timePicker) {
      // Case 2: Ranged date picker
      const splitDates = value.split(this.locale.separator);
      const updatedValue = {
        [this._startKey]: this.dateTimeFnsService.createDateFromFormat(splitDates[0], format),
        [this._endKey]: this.dateTimeFnsService.createDateFromFormat(splitDates[1], format),
      };

      // update start date
      this.setValue({
        [this._startKey]: updatedValue[this._startKey],
        [this._endKey]: updatedValue[this._endKey],
      });
      onInput ? this.picker.clickApply() : null;
    }
  }

  private validateInputValue() {
    const value = `${this._el.nativeElement.value}`;
    const format = this.dateTimeFnsService.convertFnsDateFormat(this.locale.format);
    const isSingleDate = this.singleDatePicker;
    const isValidInput = isSingleDate
      ? this.dateTimeFnsService.isValidDateString(value, format)
      : this.isValidDateRangeString(value, format);

    return !isValidInput;
  }

  private mapCalendarToString(calendar: Date[][]): string[][] {
    return calendar.map((row) => row.map((date) => this.dateTimeFnsService.formatDate(date)));
  }

  private findDateTargetInPicker(date: Date, calendar: Date[][]) {
    let row: number, col: number;
    const dateString = this.dateTimeFnsService.formatDate(date);
    const calendarString = this.mapCalendarToString(calendar);

    row = calendarString.findIndex((arr) => arr.findIndex((d) => isEqual(d, dateString)) > -1);
    col = row > -1 ? calendarString[row].findIndex((d) => isEqual(d, dateString)) : -1;

    return row < 0 || col < 0
      ? null
      : {
        row,
        col,
      };
  }

  private isValidDateRangeString(value: string, format: string): boolean {
    const splitDates = value.split(this.locale.separator);
    if (splitDates.length !== 2) {
      return false;
    }

    return (
      this.dateTimeFnsService.isValidDateString(splitDates[0], format) && this.dateTimeFnsService.isValidDateString(splitDates[1], format)
    );
  }
}
