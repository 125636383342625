import { ModuleWithProviders, NgModule } from '@angular/core';

import { OverlayModule } from '@angular/cdk/overlay';
import { DateTimeFnsService } from '@app/core/services/date-time-fns.service';
import { SharedModule } from '@app/shared/shared.module';
import { DaterangepickerFnsComponent } from './daterangepicker-fns.component';
import { DaterangepickerFnsDirective } from './daterangepicker-fns.directive';
import { LOCALE_CONFIG, LocaleFnsConfig } from './daterangepicker.config';
import { SelectModule } from '@app/shared/select/select.module';
@NgModule({
  declarations: [DaterangepickerFnsComponent, DaterangepickerFnsDirective],
  imports: [SharedModule, OverlayModule, SelectModule],
  providers: [DateTimeFnsService],
  exports: [DaterangepickerFnsComponent, DaterangepickerFnsDirective],
})
export class NgxDaterangepickerFns {
  constructor() {}
  static forRoot(config: LocaleFnsConfig = {}): ModuleWithProviders<NgxDaterangepickerFns> {
    return {
      ngModule: NgxDaterangepickerFns,
      providers: [{ provide: LOCALE_CONFIG, useValue: config }],
    };
  }
}
